.appLogo {
  height: 3em;
  width: 100%;
  background: url(./assets/images/logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.callImage {
  height: 3em;
  width: 100%;
  background: url(./assets/images/call.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.paymentTeamImage {
  height: 3em;
  width: 100%;
  background: url(./assets/images/payment_team.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.noLinkText {
  color: inherit;
  text-decoration: none;
}

.helpLink {
  color: #0A7C41 !important;
  cursor: pointer;
}

.whiteTitleBar,
.whiteTitleBar.MuiPaper-root {
  background-color: #ffffff !important;
  color: #000000 !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: none !important;
  background-image: url(./assets/images/green_shaded_line.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  font-weight: bold !important;
  /* position: fixed; */
}

.whiteTitleBar .MuiToolbar-regular {
  min-height: 58px !important;
}

.fixedPosition .whiteTitleBar .MuiToolbar-regular {
  min-height: 36px !important;
}

.fixedPosition {
  position: fixed;
  width: 100%;
}

.blackTitlebar,
.blackTitlebar.MuiPaper-root {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 0px !important;
}

.balanceSquare {
  height: 117px;
  width: 151px;
  color: #ffffff;
  background: url(./assets/images/mask_balance.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #B6412B;
  border-radius: 9px;
  text-align: center;
  display: table;
  margin: 1em auto 1em auto;
}

.balanceSquare .action {
  position: absolute;
  border: 1px solid white;
  border-radius: 20px;
  line-height: 29px;
  bottom: -18px;
  width: 100%;
  background: #95160F;
  cursor: pointer;
}

.balanceSquareInnerText {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.centerText {
  text-align: center;
}

.marginTop {
  margin-top: 1.5em !important;
}

.smallMarginTop {
  margin-top: 0.8em;
}

.lineHeightSmall {
  line-height: 1.2em !important;
}

.marginTopMinus {
  margin-top: -3.5em;
}

.marginLeftTwo {
  margin-left: 2em;
}

.paddingBottomOne {
  padding-bottom: 1em;
}

.bigText {
  font-size: 2em;
}

.boldText {
  font-weight: bold !important;
}

.nintyPercentWidth {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
}

.curvyCorners {
  border-radius: 10px;
}

.curvyTopCorners {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chartLegend {
  height: 1em;
  width: 1em;
  margin-top: 3px;
  position: absolute;
  border-radius: 2px;
}

.whiteBg {
  background-color: #ffffff;
}

.greenBg {
  background-color: #009b4a !important;
}

.signupBtn {
  background-color: #424243 !important;
  font-size: 0.8em !important;
  color: #ffffff !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  margin-top: 1.5em !important;
  font-weight: bold !important;
}

.greenText {
  color: #009b4a;
}

.whiteText {
  color: #ffffff !important;
}

.grayBg {
  background-color: #78849d;
}

.grayThickBar {
  height: 1em;
  width: 100%;
  background: #3f7387;
}

.greenBtn {
  background: #009b4a !important;
  color: #ffffff !important;
  font-size: 1em !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  margin-top: 1.5em !important;
  font-weight: bold !important;
}

.amountBtn {
  background: #ffffff !important;
  color: #3f7387 !important;
  font-size: 1em !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  margin-bottom: 0.8em !important;
  text-align: center;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.selectedAmount {
  color: #ffffff !important;
  background: #3f7387 !important;
}

.amountText {
  font-weight: bold !important;
  font-size: 1.2em !important;
}

.greenBtn .MuiButton-endIcon {
  right: 1em !important;
  top: 10px !important;
  position: absolute !important;
}

.inputFields {
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  display: block !important;
  margin-top: 1.5em !important;
  background: #ffffff !important;
}

.subTitle {
  font-size: 1.5em;
  color: #000000;
  text-align: center;
  font-weight: bold;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  display: block !important;
  margin-top: 1.5em !important;
}

.linkText {
  font-size: 0.9em;
  color: #a9afbf !important;
  text-align: center;
  font-weight: bold !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  display: block !important;
  margin-top: 1.5em !important;
  padding-top: 0px !important;
}

.formContainer {
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.formContainer.MuiGrid-container {
  width: auto !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #009b4a !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #009b4a !important;
}

.accordianTitle {
  background-color: #009b4a !important;
  border-radius: 10px !important;
  /* height: 2.5em; */
  /* padding: 7px 1em 7px 1em !important; */
  padding: 2px 1em 2px 1em !important;
  min-height: 30px !important;
  color: #ffffff !important;
}

.accordianTitle.Mui-expanded {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding: 2px 1em 2px 1em !important;
}

.accordianTitle .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.customAccordianContainer {
  border-radius: 10px !important;
}

.customAccordianContainer.MuiPaper-root {
  background-color: #ffffff !important;
  color: #747574 !important;
}

.customAccordianContainer.MuiAccordion-root:before {
  height: 0px !important;
}

.customAccordianContainer .MuiTypography-body1 {
  width: 100% !important;
}

.displayFieldLabel {
  color: #747574;
}

.displayFieldValue {
  color: #747574;
  background-color: #e2e3e2;
  width: 100%;
  margin-bottom: 1em;
  padding: 7px 1em;
  border-radius: 10px;
}

.menuIcon {
  position: absolute !important;
}

.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

.scrollingContainer {
  padding: 4px;
  overflow-y: auto;
  height: 100vh;
}

.accountInfoScroller {
  height: calc(100vh - 162px);
}

.contactUsScroller {
  height: calc(100vh - 72px);
}

.homePageScroller {
  height: calc(100vh - 261px);
}

.invoiceScroller {
  height: calc(100vh - 162px);
}

.sitesPageScroller {
  height: calc(100vh - 162px);
}

.usageScroller {
  height: calc(100vh - 178px);
}

.makePaymentScroller {
  height: calc(100vh - 126px);
}

.faqScroller {
  height: calc(100vh - 126px);
}

.dontShowOnMobile {
  display: none !important;
}

.usageUtilityContainer {
  max-width: 14.4em !important;
}

@media (max-width: 321px) {
  .usageUtilityContainer {
    max-width: 7.4em !important;
  }
  .balanceSquare {
    width: 135px !important;
  }
}

@media (min-width: 600px) {
  .usageUtilityContainer {
    max-width: 18em !important;
  }
  .formContainer {
    margin-left: auto;
    margin-right: auto;
  }
  .formContainer.MuiGrid-container {
    max-width: 360px;
  }
  .dontShowOnDesktop {
    display: none !important;
  }
  .dontShowOnMobile {
    display: block !important;
  }
  .desktopHalfScreenContainer {
    max-width: 360px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .marginTopMinus {
    margin-top: 2em;
  }
  .faqScroller {
    height: calc(100vh - 90px);
  }
  .usageScroller {
    height: calc(100vh - 135px);
  }
  .homePageScroller {
    height: calc(100vh - 189px);
  }
  .makePaymentScroller,
  .sitesPageScroller {
    height: calc(100vh - 9px);
  }
  .contactUsScroller {
    height: calc(100vh - 36px);
  }
}

.loaderContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.5);
  text-align: center;
  font-size: 1.5em;
  user-select: none;
}

.loader {
  position: absolute;
  height: 200px;
  width: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  background-image: url(./assets/images/spinner.svg);
  background-size: cover;
  animation: spin 4s linear infinite;
}

@media screen and (min-width: 600px) {
  .loader {
    left: calc(50% + 120px - 100px);
  }
}

.invoice-table table thead {
  background: #2E2E2F;
}

.invoice-table table thead tr th {
  color: #FFF;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.invoice-table table tbody tr td {
  border-bottom: 0;
  padding: 10px;
}

.invoice-table table tbody tr td:last-child {
  color: #0A7C41;
  font-weight: bold;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
