.itemContainer {
  margin: 2em 2em 0px 2em;
  border-radius: 10px;
}

.sitesTitle {
  background: #009b4a;
  color: white;
  padding: 7px 1em 7px 1em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 32px;
}

.sitesDetails {
  padding: 7px 1em 7px 1em;
  line-height: 2em;
  height: auto;
}

.utilityIcon {
  background-repeat: no-repeat !important;
  background-position: right 0px bottom 0px !important;
  background-size: contain !important;
  min-height: 36px;
}

.utilityGasIcon {
  background-image: url(../../assets/images/flamegreen.png);
}

.utilityElectricityIcon {
  background-image: url(../../assets/images/lightninggreen.png);
}

.paymentMode {
  float: left;
}

.paymentAmount {
  float: right;
}

.arrowCls {
  float: right;
  margin-top: 4px;
}
