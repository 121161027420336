.MuiBottomNavigationAction-root.Mui-selected {
  color: #009b4a !important;
}

.tabBarContainer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #fafcfb;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 9;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(214, 218, 224, 1);
  -moz-box-shadow: 0px 0px 0px 0px rgba(214, 218, 224, 1);
  box-shadow: 0px 0px 0px 2px rgba(214, 218, 224, 1);
}

@media (min-width: 600px) {
  .tabBarContainer {
    display: none !important;
  }
}
