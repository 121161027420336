.accountOverview {
  height: 108px;
}

.accountOverviewUtilityText {
  color: #407487;
  font-size: 1.3em;
  font-weight: bold;
}

.meterNumber {
  font-size: 0.9em;
  line-height: 0.8em;
}

.usageIcon {
  height: 36px;
  width: 36px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  float: left;
  position: relative;
  display: block;
}

.usageGasIcon {
  background-image: url(../../assets/images/flame.png);
}

.usageElectricityIcon {
  background-image: url(../../assets/images/lightning.png);
}

.meterPoint select {
  font-weight: bold;
}

.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container input {
  position: relative;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #E3E3E3;
  font-weight: bold;
  font-size: 16px;
  color: #0A7C41;
}

.date-range {
  position: relative;
}

.date-range svg {
  position: absolute;
  right: 4px;
  top: 9px;
  pointer-events: none;
}

.MuiFormControl-root > div {
  width: 100%;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #0A7C41 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #0A7C41 !important;
}

.data-download-container {
  text-align: center;
}

.data-download-container .linkText {
  color: #0A7C41 !important;
  font-weight: 900 !important;
}