.itemContainer {
  margin: 2em 2em 0px 2em;
  border-radius: 10px;
}

.invoiceTitle {
  background: black;
  color: white;
  padding: 7px 1em 7px 1em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.paymentDetails {
  padding: 7px 1em 7px 1em;
  line-height: 2em;
  height: 3em;
}

.paymentMode {
  float: left;
}

.paymentAmount {
  float: right;
}

.arrowCls {
  float: right;
  margin-top: 4px;
}
